import DomainContextParams from '@/utils/types/DomainContextParams';

export const LARGE_PRODUCT_ENTITY_INFO_LINKS_FRAGMENT = `
  fragment largeProductEntityInfoLinksFragment on LargeProduct {
    id
    uid
    name
    images(first: 1) {
      fullFileResource {
        schemaCode
        path
      }
    }
    categories: categoriesInContext(context: "${DomainContextParams.largeProduct}") {
      uid
      name
    }
  }
`;
